@import '../../styles/colors';

.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.thumb {
  display: inline-flex;
  margin-bottom: 8px;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  position: relative;
  margin-right: 8px;
}

.loading{
  position: absolute;
  background-color: #414B5A;
  color: #fff;
  border-radius: 12px;
  right: 0px;
  top: 0px;
  line-height: 24px;
  padding: 0 12px;
}

.thumbDelete {
  position: absolute;
  background-color: #414B5A;
  color: #fff;
  border-radius: 12px;
  right: 0px;
  top: 0px;
}

.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.thumbImg {
  display: block;
  width: auto;
  height: 100%;
}
